@import './vars';
.back {
	background-color: $purple;
	border: none;
	width: 25px;
	height: 25px;
	border-radius: 40px;
	display: flex;
	justify-content: center;
	align-items: center;
	cursor: pointer;
	transition: all 0.3s;

	&:hover,
	&:focus,
	&:active {
		background-color: lighten($purple, 10%);
	}
}
.close {
	background-color: $purple;
	border: none;
	width: 40px;
	height: 40px;
	border-radius: 40px;
	display: flex;
	justify-content: center;
	align-items: center;
	position: absolute;
	right: -20px;
	top: -20px;
	cursor: pointer;
	transition: all 0.3s;

	&:hover,
	&:focus,
	&:active {
		background-color: lighten($purple, 10%);
	}
}

@import "../styles/main.scss";

.overview {
  max-width: 970px;
  margin: 70px auto;
  width: 100%;
  padding: 0 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;

  .container {
    padding: 20px;
    display: flex;
    flex-direction: column;
    gap: 30px;
  }
  article {
    transition: all 0.3s ease-in-out 0s;
    a {
      background-color: #fff;
      display: flex;
      flex-direction: row;
      border-radius: 10px;
      min-height: 110px;
      justify-content: space-between;
      padding: 20px 30px;
      align-items: center;
      text-decoration: none;
      transition: 0.3s ease-in-out 0s;
      gap: 20px;
      flex-wrap: wrap;

      svg {
        transition: 0.3s ease-in-out 0s;
        position: relative;
        left: 0px;
      }
      &:hover {
        transform: translate3d(2px, 2px, 2px);
        -webkit-box-shadow: 10px 10px 25px 0px rgba(68, 32, 51, 0.25);
        -moz-box-shadow: 10px 10px 25px 0px rgba(68, 32, 51, 0.25);
        box-shadow: 10px 10px 25px 0px rgba(68, 32, 51, 0.25);

        svg {
          transition: 0.3s ease-in-out 0s;
          left: 5px;
        }
      }
    }
  }
}

@import "../styles/main.scss";
.modal {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.234);
  overflow-y: hidden;
  display: flex;
  justify-content: center;
  align-items: center;

  &__inner {
    max-width: 660px;
    background-color: white;
    min-height: 344px;
    width: 100%;
    margin: 0 30px;
    padding: 40px 30px;
    position: relative;
    z-index: 9;
    // @media screen and (max-width: $screen-mobile) {
    // 	width: 85vw;
    // }
  }

  &__close-layer {
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 1;
    background: transparent;
  }
  &__title {
    margin: 0;
    padding-bottom: 1em;
  }
  &__content {
    display: flex;
    flex-direction: row;
    gap: 20px;
    flex-wrap: wrap;
    justify-content: space-between;
    @media screen and (max-width: $screen-tablet) {
      flex-direction: column;
    }
    p {
      margin: 0;
      margin-bottom: 0px;
      font-size: 16px;
      line-height: 24px;
    }
    ul {
      margin: 10px 0;
      padding-left: 15px;
      display: flex;
      flex-direction: column;
      gap: 5px;
    }
    h3 {
      margin-bottom: 10px;
      font-size: 18px;
    }
    > div {
      flex: 1;
    }
  }
}

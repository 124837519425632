
.Roboto {
	font-family: 'Roboto', sans-serif;
}

@font-face {
	font-family: 'Roboto';
	font-style: normal;

	font-display: swap;
	src: url('../../assets/fonts/Roboto-Regular.eot'); /* IE9 Compat Modes */
	src: local(''),
		url('../../assets/fonts/Roboto-Regular.eot?#iefix')
			format('embedded-opentype'),
		/* IE6-IE8 */ url('../../assets/fonts/Roboto-Regular.woff2')
			format('woff2'),
		/* Super Modern Browsers */
			url('../../assets/fonts/Roboto-Regular.woff') format('woff'),
		/* Modern Browsers */ url('../../assets/fonts/Roboto-Regular.ttf')
			format('truetype'),
		/* Safari, Android, iOS */
			url('../../assets/fonts/Roboto-Regular.svg') format('svg'); /* Legacy iOS */
}

.Roboto-Bold {
	font-family: 'Roboto', sans-serif;
    font-weight: 700;
}

@font-face {
	font-family: 'Roboto';
	font-style: normal;
    font-weight: 700;
	font-display: swap;
	src: url('../../assets/fonts/Roboto-Bold.eot'); /* IE9 Compat Modes */
	src: local(''),
		url('../../assets/fonts/Roboto-Bold.eot?#iefix')
			format('embedded-opentype'),
		/* IE6-IE8 */ url('../../assets/fonts/Roboto-Bold.woff2')
			format('woff2'),
		/* Super Modern Browsers */
			url('../../assets/fonts/Roboto-Bold.woff') format('woff'),
		/* Modern Browsers */ url('../../assets/fonts/Roboto-Bold.ttf')
			format('truetype'),
		/* Safari, Android, iOS */
			url('../../assets/fonts/Roboto-Bold.svg') format('svg'); /* Legacy iOS */
}

@import '../../styles/main.scss';

.translatebutton {
	button {
		background: none;
		border: none;
		text-transform: uppercase;
		font-size: 1rem;
		@extend .Roboto-Bold;;
		font-weight: 600;
		opacity: 0.4;
		cursor: pointer;
		transition: 0.3s ease-in-out 0s;
		&.active {
			opacity: 1;
		}

		&:hover {
			opacity: 1;
		}
	}
}

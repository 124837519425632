@import '../styles/main.scss';

header {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	background-color: $bg-white;
	width: 100%;
	min-height: 70px;
	padding: 15px;

	@include md {
		position: fixed;
	}

	nav {
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: space-between;
		gap: 20px;
	}

	.logo {
		width: 100%;
		max-width: 193px;
		@include md {
			max-width: 123px;
		}
	}
}

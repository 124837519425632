// components

@import './essentials/settings.scss';
@import './essentials/fonts.scss';
@import './essentials/animations.scss';
@import './essentials/buttons.scss';

#flowchart {
	padding: 20px;
	overflow: auto;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	width: 100%;
	svg {
		margin: 50px auto;

		// width: 800px;
		image {
			pointer-events: none;
		}
	}
}

.flowchart-modal {
	opacity: 1;
	transition: all 0.3s;
	cursor: pointer;
	&:hover {
		opacity: 0.7 !important;
	}
}
.flowchart-item {
	opacity: 1;
	transition: all 0.3s;
}

.highlight {
	&-activity {
		.flowchart-item {
			opacity: 0.3;
			pointer-events: none;
		}
		.activity-block {
			opacity: 1;
			pointer-events: all;
		}
	}
	&-output {
		.flowchart-item {
			opacity: 0.3;
			pointer-events: none;
		}
		.output-block {
			opacity: 1;
			pointer-events: all;
		}
	}
	&-outcome {
		.flowchart-item {
			opacity: 0.3;
			pointer-events: none;
		}
		.outcome-block {
			opacity: 1;
			pointer-events: all;
		}
	}
	&-impact {
		.flowchart-item {
			opacity: 0.3;
			pointer-events: none;
		}
		.impact-block {
			opacity: 1;
			pointer-events: all;
		}
	}
	&-input {
		.flowchart-item {
			opacity: 0.3;
			pointer-events: none;
		}
		.input-block {
			opacity: 1;
			pointer-events: all;
		}
	}
}

// colors
$black: #000;
$white: #ffffff;
$green: #00a738;
$lightblue: #00a6df;
$orange: #f66700;
$pink: #ec2a7f;
$darkblue: #00468e;
$purple: #442033;

//bg colors
$bg-white: #fbfbfb;
$bg: #eeeeee;

//fonts




// Responsive breakpoints
$screen-desktop: 1400px;
$screen-laptop: 1200px;
$screen-laptop-small: 992px;
$screen-tablet: 768px;
$screen-mobile: 576px;

@mixin sm {
	@media (max-width: #{$screen-mobile}) {
		@content;
	}
}

@mixin md {
	@media (max-width: #{$screen-tablet}) {
		@content;
	}
}

@mixin lg {
	@media (max-width: #{$screen-laptop-small}) {
		@content;
	}
}

@mixin xl {
	@media (max-width: #{$screen-laptop}) {
		@content;
	}
}

@mixin xxl {
	@media (max-width: #{$screen-desktop}) {
		@content;
	}
}

.modal-enter .modal__inner {
	opacity: 0;
	transform: scale(0.9);
}
.modal-enter-active .modal__inner {
	opacity: 1;
	transform: translateX(0);
	transition: all 0.2s;
}
.modal-exit .modal__inner {
	opacity: 1;
}
.modal-exit-active .modal__inner {
	opacity: 0;
	transform: scale(0.9);
	transition: all 0.2s;
}

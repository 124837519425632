@import "../styles/main.scss";

footer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background-color: $bg-white;
  width: 100%;
  position: fixed;
  bottom: 0;
  .highlight {
    button {
      opacity: 0.6;
    }

    .blue {
      opacity: 1;
    }
    .green {
      opacity: 1;
    }
    .orange {
      opacity: 1;
    }
    .pink {
      opacity: 1;
    }
    .darkblue {
      opacity: 1;
    }
  }
  ul {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    min-height: 40px;
    margin: 0;
    padding: 0;
    list-style: none;
    flex-wrap: wrap;
    &:hover {
      li {
        button {
          opacity: 0.5;
        }
      }
    }
  }

  li {
    width: 100%;
    flex: 1;

    &:nth-child(1) {
      button {
        background-color: $pink;
      }
    }
    &:nth-child(2) {
      button {
        background-color: $orange;
      }
    }
    &:nth-child(3) {
      button {
        background-color: $green;
      }
    }
    &:nth-child(4) {
      button {
        background-color: $lightblue;
      }
    }
    &:nth-child(5) {
      button {
        background-color: $darkblue;
      }
    }
    button {
      width: 100%;
      color: $white;
      text-transform: uppercase;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 0.875rem;
      height: 100%;
      border: none;
      cursor: pointer;
      opacity: 1;
      transition: all 0.3s;
      width: 100%;
      min-height: 57px;
      display: flex;
      flex-direction: row;
      gap: 10px;
      align-items: center;
      justify-content: center;
      @extend .Roboto-Bold;
      @include lg {
        min-height: 40px;
      }
      @include md {
        font-size: 12px;
      }
      @include sm {
        font-size: 9px;
      }
      &:hover {
        opacity: 1 !important;
      }

      img {
        width: 13px;
        height: 13px;
        @include lg {
          width: 10px;
          height: 10px;
        }
        @include md {
          display: none;
        }
      }
    }
  }
}

@import "./vars";
*,
*:after,
*:before {
  box-sizing: border-box;
}

body {
  margin: 0;
  padding: 0;
  background-color: $bg;
  @extend .Roboto;
}

main {
  min-height: calc(100vh - 110px);
  display: flex;
  padding-bottom: 60px;
  @include md {
    padding: 60px 0;
  }
}

h1,
h2,
h3,
h4,
h5,
h6 {
  @extend .Roboto-Bold;
  @extend .Roboto-Bold;
  color: $purple;
  margin: 0;
}

h2 {
  font-size: 21px;
  line-height: 24px;
}
p {
  color: $purple;
  font-size: 14px;
  line-height: 20px;
}
img {
  width: 100%;
  display: block;
}
svg {
  text {
    @extend .Roboto;
  }
  font {
    font-size: 12.5px !important;
  }
}

.noscroll {
  overflow: hidden;
}
ul {
  margin: 0;
  padding: 0;
}

.hidden {
  display: none;
}
